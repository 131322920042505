import React, {
    ChangeEvent, FunctionComponent, useCallback, useEffect, useState, useRef,
} from 'react';
import { observer } from 'mobx-react';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { useInstances } from 'react-ioc';
import cn from 'classnames';
import { sprintf } from 'sprintf-js';
import useOnClickOutside from 'use-onclickoutside';

import I18NService from '@services/I18NService';
import { ICladAutofillProps } from '@UIElements/CladAutofill/models';
import IcDropDownSvg from '@UIElements/CountrySelect/includes/IcDropDownSvg';
import Popup from '@UIElements/Popup';
import InputCharacterCounter from '@UIElements/InputCharacterCounter';
import { IKladrResultModel, IKladrResultModelSnapshotIn, KladrResult } from '@models/mobx-state-tree/kladrResult.model';
import KladrApiService, { prepareDataForSearchCladAutoFill } from '@api/kladr-api-service';

import IcCheckSvg from './includes/IcCheckSvg';
import './includes/CladAutofill.scss';
import { Store } from '@store/store';
import KladrService from '@services/KladrService';
import { AttrsKey } from '@interfaces/form.interface';


const MIN_LENGTH = 2;

const CladAutofill: FunctionComponent<ICladAutofillProps> = (props) => {

    const [
        store,
        kladrApiService,
        { t },
        kladrService,
    ] = useInstances(
        Store,
        KladrApiService,
        I18NService,
        KladrService,
    );

    const {
        currentOrder: {
            country,
            form: {
                useAddressClassifier,
                setZipByDistrict,
                customerZipAttribute,
                customerDistrictAttribute,
                setChangeSelect,
                changeSelect,
                getDataForSubstituteId,
                setClearKladr,
                clearKladr,
            },
            id: orderId,
        },
    } = store;

    const countryCharCode = country.charCode;

    const {
        width = '200px', height, isKladerOnly, kladerInAllString,
        onChange, value, isValid, id, isKlader,
        setZipList, zipList } = props;

    const cladAutofillWrapperStyles: Record<string, string> = {
        '--countrySelect-width': width || 'auto',
        '--countrySelect-height': height || '48px',
    };

    const reallyKladerInAllString = kladerInAllString ? 1 : 0;
    const reallyIsKladerOnly = isKladerOnly ? 1 : 0;

    const formAttributeId = Number(props.formAttributeId);
    const className = props.className || '';
    const error = props.error || 'You can not enter values not from the clad';
    const label = props.label || '';
    const placeholder = props.placeholder || '';
    const length = props.length || null;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isKeyLengthEnoughToSearch, setIsKeyLengthEnoughToSearch] = useState<boolean>(true);
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [selected, setSelected] = useState<IKladrResultModel>({ id: '0', name: value } as IKladrResultModel);
    const [list, setList] = useState<IKladrResultModelSnapshotIn[]>([]);

    const cladAutofillWrapperClasses = cn([
        'clad-autofill-select__wrapper searchable',
        className,
        isMenuOpened && 'focus',
        selected.name && selected.name.length > 0 && 'has-value',
        !isValid && 'invalid',
    ]);

    useEffect((): void => {
        onChange(KladrResult.create({ ...selected }));
    }, [selected.name]);

    useEffect((): void => {
        setSelected({ ...selected, name: value } as IKladrResultModel);
    }, [value]);

    useEffect((): void=> {
        if (id === AttrsKey.CUSTOMER_ZIP &&
            !zipList?.map((item: IKladrResultModelSnapshotIn) => item.name).includes(selected.name)) {
            setSelected({ ...selected, name: '' });
        }
    }, [zipList]);

    const transferResponseToList = (results: IKladrResultModelSnapshotIn[] | undefined): IKladrResultModelSnapshotIn[] | void => {

        if (results) {

            const texts: string[] = [];

            const transformResults = results
                .reduce((acc: IKladrResultModelSnapshotIn[], x: IKladrResultModelSnapshotIn) => {
                    if (x.text && x.text.length > 0 && !texts.includes(x.text)) {
                        texts.push(x.text);
                        return [...acc, x];
                    }
                    return acc;
                }, []);

            setList(transformResults);

            return transformResults;

        }

    };

    async function fetchKladrData(valueForSearch: string): Promise<void | never> {

        if (isKlader) {

            if (id !== AttrsKey.CUSTOMER_ZIP && !!store.currentOrder?.form?.customerZipAttribute?.value.length) {
                return;
            }

            const response = useAddressClassifier ?

                await kladrService.getDataAddressClassifierSearch(
                    countryCharCode!,
                    id,
                    1,
                    valueForSearch,
                    orderId,
                    //getParentName(id) === AttrsKey.CUSTOMER_ZIP ? customerZipAttribute?.value : getParentData(id),
                ) :

                await kladrApiService.getKladrData(
                    formAttributeId,
                    reallyKladerInAllString,
                    reallyIsKladerOnly,
                    valueForSearch,
                );

            transferResponseToList(response?.results);
        }
    }

    async function fetchKladrDataCustomerDistrict(valueForSearch: string): Promise<void | never> {

        if (setZipByDistrict  && id === AttrsKey.CUSTOMER_DISTRICT && setZipList) {

            const targetAttributeId = Number(customerZipAttribute?.formAttributeId);
            const sourceAttributeId = Number(customerDistrictAttribute?.formAttributeId);

            const response = await kladrApiService.getAddressClassifierGetData(
                valueForSearch,
                sourceAttributeId,
                targetAttributeId,
            );

            if (response?.results) {
                setZipList(
                    response.results
                        .filter((x: IKladrResultModelSnapshotIn) => x.text && x.text.length > 0),
                );
            }

        }
    }

    const substituteToAddressForms = (notSelected?: boolean) => {

        setTimeout(() => {
            const data = getDataForSubstituteId(prepareDataForSearchCladAutoFill, id);

            if (data.length) {

                const newData = transferResponseToList(data);

                if (!notSelected && newData?.length === 1) {
                    setSelected({ ...selected, name: newData[0].id });
                }
            }

            setClearKladr(false);
        });
    };

    const toggleMenu = useCallback(() => {
        setIsMenuOpened(!isMenuOpened);
        if (!isMenuOpened && selected.name) {
            if (selected.name.length < MIN_LENGTH) {
                setIsKeyLengthEnoughToSearch(false);
                return;
            }

            setIsKeyLengthEnoughToSearch(true);
            setIsLoading(true);
            fetchKladrData(selected.name).finally(() => setIsLoading(false));
            fetchKladrDataCustomerDistrict(selected.name).finally(() => setIsLoading(false));
        }
        if (value.length === 0) {
            substituteToAddressForms(true);
        }
    }, [isMenuOpened]);

    const maybeFetchKladrData = useCallback(debounce((v: string) => {
        if (v.length < MIN_LENGTH) {
            return;
        }
        setIsLoading(true);
        fetchKladrData(v).finally(() => setIsLoading(false));
        fetchKladrDataCustomerDistrict(v).finally(() => setIsLoading(false));
    }, 500, { maxWait: 2000, trailing: true }), []);

    const onChangeInputValue = (e: ChangeEvent<HTMLInputElement> | string) => {
        const value = typeof e === 'string' ? e : e.target.value;
        setSelected({ ...selected, name: value } as IKladrResultModel);
        if (value.length < MIN_LENGTH) {
            setIsKeyLengthEnoughToSearch(false);
        } else {
            setIsKeyLengthEnoughToSearch(true);
        }

        maybeFetchKladrData(value);

        setIsMenuOpened(true);

        if (value?.length === 0 && id !== AttrsKey.CUSTOMER_ZIP) {
            substituteToAddressForms(true);
            setIsKeyLengthEnoughToSearch(true);
        }
    };

    const select = useCallback((value: IKladrResultModel) => {
        setSelected(value);

        if (id === AttrsKey.CUSTOMER_ZIP) {
            setClearKladr(true);
        }

        if (value?.name) {
            fetchKladrDataCustomerDistrict(value.text!).finally(() => setIsLoading(false));
        }
        setIsMenuOpened(false);
        setChangeSelect();

    }, [list]);



    useEffect(() => {
        /*
        Перед подстановкой значения в форму стираем предыдущее значение
         */
        if (clearKladr && id !== AttrsKey.CUSTOMER_ZIP) {
            setSelected({ ...selected, name: '' });
        }
        substituteToAddressForms();
    }, [changeSelect]);

    useEffect((): void => {
        if (selected) {
            select(selected);
        }
    }, []);

    const onClickOutside = () => {
        setIsMenuOpened(false);
    };

    const onBlur = () => {
        if (reallyIsKladerOnly) {
            if (!list?.map((item: IKladrResultModelSnapshotIn) => item.name?.toLowerCase()).includes(selected.name?.toLowerCase())) {
                setSelected({ id: '0', name: '' } as IKladrResultModel);
            }
        }
    };

    const onKeyDown = () => {
        setIsMenuOpened(false);
    };

    const ref = useRef(null);
    useOnClickOutside(ref, onClickOutside);

    return (
        <div ref={ref} className={cladAutofillWrapperClasses} style={cladAutofillWrapperStyles}>
            {label && (
                <div className="input-label">
                    <div className="input-label-text">{label}</div>
                </div>
            )}
            {
                (!isValid) && <div className="input-error">{error}</div>
            }
            {
                selected.name && selected.name.length && isValid
                    ? (
                        <IcCheckSvg
                            className={cn([
                                'checkIcon',
                                isMenuOpened && 'arrow--up',
                            ])}
                        />
                    )
                    : (
                        <IcDropDownSvg
                            className={cn([
                                'arrow',
                                isMenuOpened && 'arrow--up',
                            ])}
                        />
                    )
            }
            <input
                id={id}
                type="text"
                placeholder={placeholder}
                value={selected.name !== null ? selected.name : ''}
                onChange={onChangeInputValue}
                autoComplete="disabled"
                onClick={toggleMenu}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
            />
            <Popup
                className="select-popup"
                isActive={isMenuOpened}
                width={width}
            >
                <ul className="values">
                    {isLoading && (
                        <li className="no-results">
                            {t('Загрузка...', 'Loading...')}
                        </li>
                    )}

                    {
                        !isLoading && isKeyLengthEnoughToSearch && zipList && zipList.length > 0 && id === AttrsKey.CUSTOMER_ZIP
                        && map(zipList, (value: IKladrResultModel, index: number) => (
                            <li className="result" key={index} onClick={() => select(value)}>
                                {value.name}
                            </li>
                        ))
                    }

                    {!isLoading && !isKeyLengthEnoughToSearch && (
                        <li className="no-results">
                            {sprintf(t(
                                'Пожалуйста введите %d символов для поиска',
                                'Please enter %d symbols to search',
                            ), MIN_LENGTH)}
                        </li>
                    )}

                    {
                        !isLoading && isKeyLengthEnoughToSearch && list.length > 0
                        && map(list, (value: IKladrResultModel, index: number) => (
                            <li className="result" key={index} onClick={() => select(value)}>
                                {value.name}
                            </li>
                        ))
                    }


                    {!isLoading && isKeyLengthEnoughToSearch && list.length === 0  &&
                        id !== AttrsKey.CUSTOMER_ZIP && (
                        <li className="no-results">
                            {t('Нет результатов', 'No results')}
                        </li>
                    )}

                    {!isLoading && isKeyLengthEnoughToSearch && zipList?.length === 0 && id === AttrsKey.CUSTOMER_ZIP &&
                        list.length === 0  &&
                        (
                            <li className="no-results">
                                {t('Нет результатов', 'No results')}
                            </li>
                        )}
                </ul>
            </Popup>
            {length && value.length !== 0 && <InputCharacterCounter count={value.length} length={length} />}
        </div>
    );
};


export default observer(CladAutofill);

import i18next from 'i18next';
import map from 'lodash/map';
import filter from 'lodash/filter';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import BackendAdapter from 'i18next-multiload-backend-adapter';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import mapValues from 'lodash/mapValues';

import { getEnvGetTranslationsEndpoint } from '@/env/requestEndpoints';
import { ITranslationsData } from '@api/translation-api-service/model';
import {
    ICreateI18nextInstance, IGetConfigHttpAndLocalStorage,
    IGetMultiBackendOptions, IHttpBackendOptions, ILocalStorageBackendOptions,
    IMapTranslationsData,
    IParse,
} from '@interfaces/i18n/models';


const mapTranslationsData: IMapTranslationsData = (data: ITranslationsData) => mapValues(data, ((value) => ({
    translation: value && value.common,
})));


const parse: IParse = (res) => {
    try {
        const resJson = JSON.parse(res);

        return mapTranslationsData(resJson.data);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return {};
    }
};

const getMultiBackendOptions: IGetMultiBackendOptions = () => ({
    loadPath: (_lngs) => {
        // Русские переводы не нужны, у нас ключи и так на русском уже
        const lngs = filter(_lngs, (lng) => lng.toLowerCase() !== 'ru-ru' && lng.toLowerCase() !== 'ru');
        const languagesParams = new URLSearchParams(map(lngs, (lng) => ['languages[]', encodeURIComponent(lng)]));
        const languagesParamsQuery = languagesParams.toString();

        return `${getEnvGetTranslationsEndpoint()}?${languagesParamsQuery}`;
    },
    allowMultiLoading: true,
    parse,
    crossDomain: true,
});

const localStorageBackendOptions: ILocalStorageBackendOptions = {
    prefix: 'i18next_translation_',
    expirationTime: 24 * 60 * 60 * 1000, // 1 сутки
};

const getConfigHttpAndLocalStorage: IGetConfigHttpAndLocalStorage = () => {
    const multiBackendOptions = getMultiBackendOptions();

    const Backend = new BackendAdapter(null, getMultiBackendOptions());

    const httpBackendOptions: IHttpBackendOptions = {
        backend: HttpApi,
        backendOption: multiBackendOptions,
    };

    return {
        backends: [
            LocalStorageBackend,
            Backend,
        ],
        backendOptions: [
            localStorageBackendOptions,
            httpBackendOptions,
        ],
    };
};

const createI18nextInstance: ICreateI18nextInstance = async () => i18next
    .use(ChainedBackend)
    .use(LanguageDetector) // detect user lang https://github.com/i18next/i18next-browser-languageDetector
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        backend: getConfigHttpAndLocalStorage(),
        nsSeparator: '🤖🤖🤖️',
        keySeparator: '👽👽',
        // расскоментируй для поиска и сбора недостающих переводов
        // saveMissing: true,
        // missingKeyHandler: (lng, ns, key, fallbackValue) => {
        //     const itemsRaw = localStorage.getItem('items');
        //     const items = itemsRaw ? JSON.parse(itemsRaw) : {};
        //     if (!items[lng[0]]) {
        //         items[lng[0]] = [];
        //     }
        //
        //     items[lng[0]].push({ key, defaultValue: fallbackValue });
        //     items[lng[0]] = uniqBy(items[lng[0]], 'key');
        //     localStorage.setItem('items', JSON.stringify(items));
        // },
        // Убрал дефодтный язык и fallbackLng чтобы по умолчанию у нас все было на русском.
        // Сервис может отправлять callback если фразы на текущем языке нет. Возможно стоит явно добавить русский и собирать эти сообщения.
        // lng: 'ru-RU',
        fallbackLng: 'en-US', // use en if detected lng is not available
        debug: false,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        // // react-i18next options
        react: {
            wait: false,
            bindI18n: 'languageChanged loaded',
            // bindStore: 'added removed',
            nsMode: 'default',
            useSuspense: false,
        },
    });

export default createI18nextInstance;

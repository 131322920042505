import React, {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import OrderInfo from '@components/main/order-page/order-info/OrderInfo';
import CallPanel from '@components/main/order-page/call-panel/CallPanel';
import CallHistoryWidget from '@components/main/order-page/call-history/CallHistoryWidget/CallHistoryWidget';
import ProductCard from '@components/main/order-page/product-card/ProductCard';
import OrderForm from '@components/main/order-page/OrderForm';
import OrderCustomerInfo from '@components/main/order-page/order-customer-info';
import OrderProducts from '@components/main/order-page/order-products';
import OrderAddress from '@components/main/order-page/order-address';
import OrderShipping from '@components/main/order-page/order-shipping';

import { OrderService, SipService } from '@/app/services';
import { Store } from '@store/store';
import getShowInDevItems from '@/env/getShowInDevItems';
import UserService from '@services/UserService';
import I18NService from '@services/I18NService';
import OrderUpdateEventSubscriptionService from '@services/subscription/OrderUpdateEventSubscriptionService';
import FloatingCallButton from '@UIElements/FloatingCallButton/FloatingCallButton';
import BottomMobileTabs, { BottomMobileTabsPageEnum } from '@components/main/bottom-mobile-menu/BottomMobileTabs';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import { DEFAULT_USER } from '@core/constants/defaultStoreItems';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import CallAutomatizationHandler from '@/app/services/call-automatization/CallAutomatizationHandler';
import PddLimitHandler from '@/app/services/orderStatusAutomatization/PddLimitHandler';
import SipErrorCauseHandler from '@/app/services/orderStatusAutomatization/SipErrorCauseHandler';
import NoAnswerAfterSecHandler from '@/app/services/orderStatusAutomatization/NoAnswerAfterSecHandler';
import SnackbarController from '@/app/ui-elements/Snackbar/SnackbarController';
import OrderCommentsAndSms from './order-comments-and-sms';
import NotificationPollingService from '@services/NotificationPollingService';
import SipServiceBaseEventSubscriptionService from '@services/sip/SipServiceBaseEventSubscriptionService';
import ClientServiceModeEventSubscriptionService
    from '@services/subscription/ClientServiceModeEventSubscriptionService';
import CommonStorageEventSubscriptionService from '@services/subscription/CommonStorageEventSubscriptionService';
import NotificationSubscriptionService from '@services/subscription/NotificationSubscriptionService';
import InternetSpeedMetricSubscriptionService from '@services/subscription/InternetSpeedMetricSubscriptionService';
import CookieSubscriptionService from '@services/subscription/CookieSubscriptionService';
import KibanaService from '@/app/services/KibanaService';
import Spinner from '@UIElements/Spinner';
import useQueriesHook from '@components/hooks/useQueriesHook';
import { UNEDITABLE } from '@core/constants/urlSearchParams';
import CallHandler from '@services/call-handler/CallHandler';
import { NEED_RELOAD_PAGE } from '@core/constants/reloadPage';
import LocalStorageService from '@core/services/LocalStorageService';
import { NO_INTERNET_CONNECTION } from '@core/constants/noInternetConnection';


const OrderPage: FunctionComponent = (): JSX.Element => {
    const [showInDevItems] = useState<boolean>(
        () => getShowInDevItems(),
    );

    const [
        sipService,
        orderService,
        {
            clearCurrentOrder,
            inactivityTimer,
            currentOrder: {
                id: orderId,
                isACreatedOrder,
                isEmptyCurrentOrder,
                country: currentCountry,
                form: {
                    showWholesaleConfirm,
                    showWholesaleConfirmBasketId,
                    showWholesaleConfirmQuantity,
                },
                selectedPhoneToCallForComponent,
                setForceInactiveButtonOfCall,
                callAutomatization: { setPhoneNumberNotFound },
                startCallAutomatic,
                additionalParamsCurrentOrder: {
                    loadBasket,
                },
            },
            ui: {
                bottomMobileTab: {
                    activeBottomTab,
                },
            },
            currentUser: {
                id: userId,
                mode,
                setIsReady,
                unreadyAfterSave,
                setUnreadyAfterSave,
                needSpeedTest,
            },
        },
        {
            isAuthCookieValid,
        },
        { t },
        orderUpdateEventSubscriptionService,
        callAutomatization,
        callHandler,
        pddLimitHandler,
        noAnswerAfterSecHandler,
        sipErrorCauseHandler,
        notificationPollingService,
        sipServiceBaseEventSubscriptionService,
        clientServiceModeEventSubscriptionService,
        commonStorageEventSubscriptionService,
        notificationSubscriptionService,
        internetSpeedMetricSubscriptionService,
        cookieSubscriptionService,
        kibanaService,
        localStorageService,
    ] = useInstances(
        SipService,
        OrderService,
        Store,
        UserService,
        I18NService,
        OrderUpdateEventSubscriptionService,
        CallAutomatizationHandler,
        CallHandler,
        PddLimitHandler,
        NoAnswerAfterSecHandler,
        SipErrorCauseHandler,
        NotificationPollingService,
        SipServiceBaseEventSubscriptionService,
        ClientServiceModeEventSubscriptionService,
        CommonStorageEventSubscriptionService,
        NotificationSubscriptionService,
        InternetSpeedMetricSubscriptionService,
        CookieSubscriptionService,
        KibanaService,
        LocalStorageService,
    );

    const {
        pddLimitEndCall, statusAutomatizationActive, noAnswerIfBusy,
    } = currentCountry;

    const onUnloadHandler = (): void => sipService.endCall();
    const internetConection = localStorage.getItem(NO_INTERNET_CONNECTION);

    const { paramsValues } = useQueriesHook([`${UNEDITABLE}`]);
    const { uneditable } = paramsValues;

    useEffect(
        () => {

            cookieSubscriptionService.subscribe();
            sipServiceBaseEventSubscriptionService.subscribe();
            sipService.subscribe();
            notificationSubscriptionService.subscribe();
            commonStorageEventSubscriptionService.subscribe();
            internetSpeedMetricSubscriptionService.subscribe();
            notificationPollingService.init();

            window.addEventListener('unload', onUnloadHandler);

            if (mode === UserModeEnum.CLIENT_SERVICE) {
                clientServiceModeEventSubscriptionService.subscribe();
            }

            // Если нет метки для замеров скорости интернета, то скачиваем заказ обычным способом
            if (!needSpeedTest) {

                if (mode === 'REGULAR') {
                    void orderService.startPollingOrder();
                }
            }

            callHandler.subscribe();

            return () => {
                cookieSubscriptionService.unsubscribe();
                sipServiceBaseEventSubscriptionService.unsubscribe();
                sipService.unsubscribe();
                notificationSubscriptionService.unsubscribe();
                commonStorageEventSubscriptionService.unsubscribe();
                internetSpeedMetricSubscriptionService.unsubscribe();

                window.removeEventListener('unload', onUnloadHandler);

                notificationPollingService.destroy();

                setIsReady(false);

                if (mode === UserModeEnum.CLIENT_SERVICE) {
                    clientServiceModeEventSubscriptionService.unsubscribe();
                }
            };
        },
        [],
    );

    useEffect(() => {
        if (localStorageService.getItem(NEED_RELOAD_PAGE)) {
            document.location.reload();
            localStorageService.removeItem(NEED_RELOAD_PAGE);
        }
    }, [localStorageService.getItem(NEED_RELOAD_PAGE)]);

    /**
     * 1. [PddLimitHandler] Инициируем наблюдение за звонком
     * Чтобы сбросить его по достижению лимита PDD
     * 2. [noAnswerAfterSecHandler] Инициируем наблюдение за звонком в статусе PROGRESS
     * Чтобы автоматически выставить NO_ANSWER после N секунд неответа
     * 3. [sipErrorCauseHandler] Инициируем наблюдение за причинами завершения звонка
     * Чтобы проставить причину в callHistory,
     * а также в определенных случаях автоматизировать смену статуса
     * на NO_ANSWER, либо RECALL по завершению звонка
     */
    useEffect(() => {
        if (pddLimitEndCall) {
            pddLimitHandler.subscribe();
        }

        if (!isACreatedOrder) {
            /**
             * для созданных заказов не выполяем автоматизацию статусов по кейсу NoAnswerAfterSec
             */
            if (statusAutomatizationActive) {
                noAnswerAfterSecHandler.subscribe();
            }

            sipErrorCauseHandler.subscribe();
        }

        return () => {
            pddLimitHandler.unsubscribe();
            noAnswerAfterSecHandler.unsubscribe();
            sipErrorCauseHandler.unsubscribe();
        };
    }, [pddLimitEndCall, statusAutomatizationActive, noAnswerIfBusy, orderId, isACreatedOrder]);

    useEffect(() => {
        /**
         * Для созданных заказов не включаем автонабор
         */
        if (!isACreatedOrder) {
            callAutomatization.subscribe();
        }

        return () => {
            callAutomatization.unsubscribe();
        };
    }, [orderId, isACreatedOrder, startCallAutomatic]);

    const { isCallingRightNow } = sipService;

    /**
     * Если это созданный заказ и в нём нет номера телефона, то блокируем кнопку звонка
     */
    useEffect(() => {
        const phone = selectedPhoneToCallForComponent();
        if (isACreatedOrder && !isCallingRightNow && (!phone || !phone.value || !phone.value.length)) {
            setForceInactiveButtonOfCall(true);
            // для сервиса автонабора нужно проставить флаг, что номера телефона нет
            setPhoneNumberNotFound(true);
        }
    }, [isACreatedOrder, selectedPhoneToCallForComponent, isCallingRightNow]);

    const { anyTimerActive } = inactivityTimer;

    const conditionForLeavingPage = useMemo<boolean>(
        () => isAuthCookieValid && (isCallingRightNow || !isEmptyCurrentOrder),
        [isAuthCookieValid, isCallingRightNow, isEmptyCurrentOrder],
    );

    const onBeforeUnload = (e: BeforeUnloadEvent): string | false => {
        if (conditionForLeavingPage) {
            // Повальное большинство браузеров не показывают сообщение с нашим текстом, а только со своим.
            const dialogText = t(
                'Вы уверены что хотите покинуть страницу? У вас есть не сохраненные изменения.',
                'Are you sure you want to leave the page? You have unsaved changes.',
            );

            e.returnValue = dialogText;

            return dialogText;
        }

        return false;
    };
    useEffect(() => {
        if (internetConection) {
            void kibanaService.internetConectionLog(NO_INTERNET_CONNECTION, internetConection);
        }
    }, [internetConection]);

    useEffect(() => {
        if (showWholesaleConfirm && showWholesaleConfirmBasketId && showWholesaleConfirmQuantity) {
            orderService.onWholesaleRequest(showWholesaleConfirmBasketId, showWholesaleConfirmQuantity);
        }
    }, [showWholesaleConfirm]);

    useEffect(() => {
        window.addEventListener('beforeunload', onBeforeUnload);

        if (userId !== DEFAULT_USER.id) {
            orderUpdateEventSubscriptionService.subscribe();
        }

        if (userId !== DEFAULT_USER.id && mode !== UserModeEnum.CLIENT_SERVICE) {
            if (!sipService.hasSipOption) {
                sipService.sipErrorMessage = 'Missing SIP account data';
                sipService.endCall();
                clearCurrentOrder();
                orderService.stopPollingOrder();
                setIsReady(false);
            }
        }

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);

            if (mode !== UserModeEnum.CLIENT_SERVICE) {
                sipService.sipErrorMessage = '';
                sipService.endCall();
                orderService.stopPollingOrder();
            }

            orderUpdateEventSubscriptionService.unsubscribe();
            clearCurrentOrder();

            if (unreadyAfterSave) {
                setIsReady(false);
                setUnreadyAfterSave(false);
            }
        };
    }, [userId]);

    return (
        <>
            <div className="orderPage__wrapper">
                {/*TODO В react-router-dom v6 Prompt на данный момент не поддерживается*/}
                {/*{conditionForLeavingPage && (*/}
                {/*    <Prompt*/}
                {/*        when={conditionForLeavingPage}*/}
                {/*    >*/}
                {/*        {({ onConfirm, onCancel }) => orderService.leavePageModal(*/}
                {/*            onCancel,*/}
                {/*            onConfirm,*/}
                {/*            isCallingRightNow,*/}
                {/*        )}*/}
                {/*    </Prompt>*/}
                {/*)}*/}

                {/* <ProfileInfoPanel /> */}

                <div className={cn('page', activeBottomTab === BottomTabEnum.MENU && 'hidden')}>
                    <div className="page-content">
                        <OrderForm
                            floatingCallButton={(
                                <FloatingCallButton />
                            )}
                            inactivity={
                                <div />
                            }
                        >
                            <div
                                className={cn(
                                    'b-order',
                                    anyTimerActive && 'with-timer',
                                    uneditable !== null && 'b-order__uneditable',
                                )}
                            >
                                <SnackbarController />
                                <OrderInfo
                                    country={currentCountry}
                                    callHistory={
                                        <CallHistoryWidget />
                                    }
                                />

                                {showInDevItems && (
                                    <ProductCard />
                                )}

                                <OrderCustomerInfo />

                                {loadBasket ?
                                    <div className='page-content__loader'>
                                        <Spinner className="preloader"/>
                                    </div>
                                    :
                                    <>
                                        <OrderCommentsAndSms />
                                        <OrderProducts />
                                    </>
                                }

                                <OrderAddress />

                                <OrderShipping />
                            </div>
                        </OrderForm>
                    </div>
                    <CallPanel />
                </div>
                <BottomMobileTabs page={BottomMobileTabsPageEnum.ORDER} />
            </div>
        </>
    );
};


export default observer(OrderPage);

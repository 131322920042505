import { UA, WebSocketInterface } from 'jssip';
import { action, computed, observable } from 'mobx';

import I18NService, { customI18NTFunction } from '@services/I18NService';
import { CallOptions, IncomingMessageEvent, IncomingRTCSessionEvent, OutgoingRTCSessionEvent } from 'jssip/lib/UA';
import { AnswerOptions, EndEvent, Originator, RTCSession } from 'jssip/lib/RTCSession';
import {
    EnumSipStatus,
    IAsteriskHangupCause,
    ISipOptions,
    ISipServiceBase,
    SipError,
    SipErrorHeadersEnum,
    SipHttpErrorCode,
} from '@services/sip/models';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { Store } from '@store/store';
import { OrderFetchService, OrderService, UserService } from '@services/index';
import DevicesService from '@core/services/DevicesService';
import ScriptDataService from '@services/script/ScriptDataService';
import { causes } from 'jssip/lib/Constants';
import { IncomingResponse } from 'jssip/lib/SIPMessage';
import { CustomerModeStore } from '@store/customerModeStore';
import ModalService from '@core/services/ModalService';
import LocalStorageService from '@core/services/LocalStorageService';
import CallHistoryApiService from '@api/call-history-api-service';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';


interface IConnectFn {
    (sipLogin: string, sipPass: string, sipHost: string): void;
}


class SipServiceBase implements ISipServiceBase {
    @observable
    public reconnectionRequired = false;

    @observable
    private _asteriskHangupCauseCode: string | undefined;

    @observable
    private _asteriskHangupCauseText: string | undefined;

    @action
    private _setAsteriskHangupCause = (code: string, text: string): void => {
        this._asteriskHangupCauseCode = code;
        this._asteriskHangupCauseText = text;
    };

    @action
    private _clearAsteriskHangupCause = (): void => {
        this._asteriskHangupCauseCode = undefined;
        this._asteriskHangupCauseText = undefined;
    };

    @observable
    private _sipErrorCause: causes | undefined;

    @observable
    private _sipErrorCauseOriginator: Originator | undefined = undefined;

    public zorraTaskId: number | undefined;

    private _recallAvailability = false;

    private _server: string;

    public getSipErrorCause() {
        return this._sipErrorCause;
    }

    public getSipErrorCauseOriginator() {
        return this._sipErrorCauseOriginator;
    }

    /**
     * На поля _sipErrorCause и _sipErrorCauseOriginator смотрит mobx reaction
     *
     * Чтобы она не триггерилась лишний раз,\
     * устанавливаем значения в эти свойства 1 раз
     */
    @action
    public setSipErrorCauseAndOriginator = (cause: causes | undefined, originator: Originator | undefined) => {
        this._sipErrorCause = cause;
        this._sipErrorCauseOriginator = originator;
    };

    public getAsteriskHangupCause = (): IAsteriskHangupCause | null => {
        if (this._asteriskHangupCauseCode && this._asteriskHangupCauseText) {
            return {
                code: this._asteriskHangupCauseCode,
                text: this._asteriskHangupCauseText,
            };
        }

        return null;
    };

    // Отключен микрофон или нет
    @observable
    private _muted = false;

    public getMuted = (): boolean => this._muted;

    @action
    public setMuted = (muted: boolean) => {
        this._muted = muted;
    };

    @observable
    private _socket: WebSocketInterface | undefined;

    @observable
    private _session: RTCSession | undefined;

    @observable
    private _ua: UA | undefined;

    @observable
    public _prevStatus = EnumSipStatus.DISCONNECTED;

    @observable
    public _status: EnumSipStatus;

    @observable
    private _sipErrorCode: number | null;

    @observable
    private _sipErrorMessage: string | null;

    // Время текущего звонка в секундах
    @observable
    private _callTime = 0;

    // Фиксируем номер телефона с которым сейчас идет соединение
    @observable
    private _phoneNumber = '';

    @action
    private setPhoneNumber = (phoneNumber: string) => {
        this._phoneNumber = phoneNumber;
    };

    public getPhoneNumber = (): string => this._phoneNumber;

    private get _t(): customI18NTFunction {
        return this._I18NService.t;
    }

    private get _currentUser(): IUserModel {
        return this._store.currentUser;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    // Звоним ли мы прямо сейчас
    @computed
    public get isCallingRightNow(): boolean {
        if (this.getStatus() === null) {
            return false;
        }

        return this.getStatus() >= EnumSipStatus.DIALING && this.getStatus() <= EnumSipStatus.ENDED;
    }

    // Есть ли установленная сессия звонка в данный момент
    @computed
    public get hasSession(): boolean {
        return !!this._session;
    }

    public getSession = (): RTCSession | undefined => this._session;

    public get sipError(): SipError {
        return {
            code: this.sipErrorCode,
            error: this.getSipErrorCause() || null,
            message: this.sipErrorMessage,
        };
    }

    public get callDirection(): string {
        if (this._session) {
            return this._session.direction;
        }

        return '';
    }

    public get isConnected(): boolean {
        if (this._ua) {
            return this._ua.isConnected();
        }

        return false;
    }

    public get isRegistered(): boolean {
        if (this._ua) {
            return this._ua.isRegistered();
        }

        return false;
    }

    @action
    public setStatus(status: EnumSipStatus, sipCode?: SipHttpErrorCode) {
        this._status = status;

        if (sipCode) {
            this._orderService.setSipCode(sipCode);
        }

        if (this._prevStatus !== this._status) {
            this._prevStatus = this._status;
        }
    }

    public getStatus(): EnumSipStatus {
        if (this._status) {
            return this._status;
        }

        return EnumSipStatus.DISCONNECTED;
    }

    @action
    private _setSipErrorMessage(message: string | null) {
        this._sipErrorMessage = message;
    }

    public set sipErrorMessage(message: string | null) {
        this._setSipErrorMessage(message);
    }

    public get sipErrorMessage(): string | null {
        return this._sipErrorMessage;
    }

    @action
    private _setSipErrorCode(code: number | null) {
        this._sipErrorCode = code;
    }

    public set sipErrorCode(code: number | null) {
        this._setSipErrorCode(code);
    }

    public get sipErrorCode(): number | null {
        return this._sipErrorCode;
    }

    @action
    public setCallTime(callTime: number) {
        this._callTime = callTime;
    }

    public getCallTime(): number {
        if (this._callTime) {
            return this._callTime;
        }

        return 0;
    }

    public get recallAvailability(): boolean {
        if (!this._store.currentOrder.form.enableRecallAfterCallSeconds) {
            return true;
        }
        return this._recallAvailability;
    }

    public setRecallAvailability(recallAvailability: boolean) {
        this._recallAvailability = recallAvailability;
    }

    constructor(
        private readonly _store: Store,
        private readonly _I18NService: I18NService,
        private readonly _userService: UserService,
        private readonly _devicesService: DevicesService,
        private readonly _customerModeStore: CustomerModeStore,
        private readonly _orderFetchService: OrderFetchService,
        private readonly _modalService: ModalService,
        private readonly _orderService: OrderService,
        private readonly _localStorageService: LocalStorageService,
        private readonly _callHistoryApiService: CallHistoryApiService,
        private readonly _scriptDataService: ScriptDataService,
    ) {
        this._status = EnumSipStatus.DISCONNECTED;
    }

    public setZorraTaskId(zorraTaskId: number | undefined) {
        this.zorraTaskId = zorraTaskId;
    }

    public getZorraTaskId(): number {
        return this.zorraTaskId!;
    }

    @action
    private async hardwareErrorHandling(): Promise<void> {
        try {
            await this._devicesService.checkEquipmentForWork();
        } catch (error) {
            this.sipErrorMessage = error.message;
        }
    }

    private async callProcessFailed(e: EndEvent): Promise<void> {
        const { cause, message, originator } = e;

        this._currentOrder.setAbilityToDropCall(false);

        // this.setPhoneNumber(''); Перетирался телефон при возникновении ошибки - временно закомментриовал
        this.setMuted(false);
        this.setSipErrorCauseAndOriginator(
            Object.values(causes).find((value) => value === cause),
            originator,
        );

        if ((originator === 'local' || originator === 'remote') && (cause === causes.CANCELED || cause === causes.REJECTED)) {
            this.setStatus(EnumSipStatus.ENDED);
        } else if (cause === causes.BUSY) {
            this.setStatus(EnumSipStatus.ENDED, SipHttpErrorCode.BUSY_HERE);
        } else {
            if (this._store.currentUser.mode === UserModeEnum.PROGRESSIVE) {
                void this._modalService.showErrorNotificationModal(
                    this._t('Произошла ошибка. Обратитесь в службу технической поддержки.',
                        'An error has occurred. Contact technical support.'));
            }
            this.setStatus(EnumSipStatus.FAIL, SipHttpErrorCode.SERVICE_UNAVAILABLE);
        }

        if (message instanceof IncomingResponse) {
            this.sipErrorCode = message.status_code;
        }

        if (
            message
            && message.hasHeader(SipErrorHeadersEnum.X_ASTERISK_HANGUPCAUSECODE)
            && message.hasHeader(SipErrorHeadersEnum.X_ASTERISK_HANGUPCAUSE)
        ) {
            this._setAsteriskHangupCause(
                message.getHeader(SipErrorHeadersEnum.X_ASTERISK_HANGUPCAUSECODE),
                message.getHeader(SipErrorHeadersEnum.X_ASTERISK_HANGUPCAUSE),
            );
        }

        if (originator === 'remote') {
            const mainMessage = `SIP Error ${this.sipErrorCode}: `;

            if (cause === causes.NOT_FOUND) {
                this.sipErrorMessage = `${mainMessage}${causes.NOT_FOUND}`;
            }

            if (cause === causes.BUSY) {
                this.sipErrorMessage = `${mainMessage}${causes.BUSY}`;
            }

            if (
                cause === causes.SIP_FAILURE_CODE
                && this.sipErrorCode === SipHttpErrorCode.SERVICE_UNAVAILABLE
            ) {
                if (this._asteriskHangupCauseText) {
                    this.sipErrorMessage = `${mainMessage}${this._asteriskHangupCauseText}`;
                } else {
                    this.sipErrorMessage = `${mainMessage}${causes.SIP_FAILURE_CODE}`;
                }
            }
        }

        if (originator === 'local') {
            if (cause === causes.USER_DENIED_MEDIA_ACCESS) {
                await this.hardwareErrorHandling();
            }
        }

        if (this.reconnectionRequired) {
            await this.connectToANewHost();
        }

        console.log('call failed');
    }

    public switchProgressiveToRegularMode(): void {
        this._currentOrder.setForceInactiveButtonOfCall(true);
        this._currentUser.setDisableProgressiveMode(true);
        this.disconnect();
    }


    private async callProcessEnded(e: EndEvent): Promise<void> {
        const { cause, originator } = e;
        this.setMuted(false);
        this.setPhoneNumber('');
        this.setStatus(EnumSipStatus.ENDED);

        this._currentOrder.setAbilityToDropCall(false);

        this.setSipErrorCauseAndOriginator(
            Object.values(causes).find((value) => value === cause),
            originator,
        );
        if (this.reconnectionRequired) {
            await this.connectToANewHost();
        }

        if (this._currentUser.mode === UserModeEnum.PROGRESSIVE && !this._currentOrder.isEmptyCurrentOrder) {
            this.switchProgressiveToRegularMode();
        }

        console.log('call ended');
    }

    private callProcessConnecting(/*e: ConnectingEvent*/): void {
        this.setStatus(EnumSipStatus.DIALING, SipHttpErrorCode.TRYING);

        console.log(`Call started with ${this._currentUser?.sipPlatinum?.sipHost}`);
        console.log('connecting....');
    }

    private callProcessProgress(/*e: IncomingEvent | OutgoingEvent*/): void {
        this.setStatus(EnumSipStatus.PROGRESS, SipHttpErrorCode.RINGING);
        console.log('call is in progress');
    }

    private callProcessConfirmed(/*e: IncomingEvent | OutgoingEvent*/): void {
        this.setStatus(EnumSipStatus.LIVE, SipHttpErrorCode.OK);
        // Событие происходит когда поднимают трубку.
        console.log('call confirmed');

    }

    public getSipOptions = (): null | ISipOptions => {
        if (!this._currentUser) {
            return null;
        }

        const { mode, sipAutoCall, sipPlatinum } = this._currentUser;

        if (mode === UserModeEnum.REGULAR || mode === UserModeEnum.PROGRESSIVE) {
            if (!sipPlatinum || !sipAutoCall) {
                return null;
            }

            const {
                sipHost,
                sipLogin,
                sipPass,
            } = mode === UserModeEnum.PROGRESSIVE ? sipAutoCall : sipPlatinum;

            if (sipHost && sipLogin && sipPass) {
                return {
                    host: sipHost,
                    login: sipLogin,
                    password: sipPass,
                };
            }

            return null;
        }

        if (this._currentUser.mode === UserModeEnum.CLIENT_SERVICE) {
            const { sipHost } = this._customerModeStore.store;
            const {
                sipLogin,
                sipPass,
            } = this._currentUser.sipPlatinum;

            if (sipHost && sipLogin && sipPass) {
                return {
                    host: sipHost,
                    login: sipLogin,
                    password: sipPass,
                };
            }

            return null;
        }

        return null;
    };

    public collectDataForConnectionAndConnect = (): void => {
        const { mode, isReady } = this._currentUser;

        const isNotReadyInClientMode = !isReady && mode === UserModeEnum.CLIENT_SERVICE;

        if (this.isConnected || isNotReadyInClientMode) {
            return;
        }

        const sipOptions = this.getSipOptions();

        if (sipOptions) {
            console.log('sipOptions');

            this._connect(
                sipOptions.login,
                sipOptions.password,
                sipOptions.host,
            );
        }
    };

    public async connectToANewHost(sipHostFromEvent?: string): Promise<void> {

        let userData;

        if (!sipHostFromEvent) {
            userData = await this._userService.userRequest();
        }

        if (userData || sipHostFromEvent) {
            const sipHost = sipHostFromEvent ? sipHostFromEvent : this._currentUser.sipPlatinum.sipHost;

            console.log('Connecting to a new sip host', sipHost);

            if (sipHost) {
                this._userService.setSipHost(sipHost);
            }

            this.collectDataForConnectionAndConnect();

            this.disconnect();

            this.reconnectionRequired = false;

        }
    }

    public call(phone: string): void {
        if (!this._ua || !this.isConnected || phone.trim().length === 0) {
            return;
        }

        this.setPhoneNumber(phone);
        this.setSipErrorCauseAndOriginator(undefined, undefined);

        const options: CallOptions = {
            eventHandlers: {
                connecting: this.callProcessConnecting.bind(this),
                progress: this.callProcessProgress.bind(this),
                ended: this.callProcessEnded.bind(this),
                failed: this.callProcessFailed.bind(this),
                confirmed: this.callProcessConfirmed.bind(this),
            },
            mediaConstraints: {
                audio: true,
                video: false,
            },
        };

        try {
            this._session = this._ua.call(phone, options);

        } catch (e) {
            console.log(e);
            this.setStatus(EnumSipStatus.FAIL, SipHttpErrorCode.SERVICE_UNAVAILABLE);
            this.sipErrorMessage = e.message;
            this.sipErrorCode = null;
            return;
        }

        if (this._session && this._session.connection) {
            this._session.connection.ontrack = (e) => {
                console.log('ontrack event');
                const audio = document.createElement('audio');
                const [stream] = e.streams;
                audio.srcObject = stream;
                void audio.play();
            };
        }
    }

    private _getOrderFromProgressiveAutoCall = async (event: IncomingMessageEvent): Promise<void> => {

        const { request: { body } } = event;

        const taskId = Number(JSON.parse(body).task_id);
        this._localStorageService.setItem('orderId', this._store.currentOrder.id);
        this._localStorageService.setItem('taskIdProgressiveAutocall', taskId);

        this.setZorraTaskId(taskId);

        try {
            /**
             * Получаем ордер после того как клиент ответил на звонок (при прогрессивном автодозвоне)
             */
            await this._orderFetchService.fetchOrderFromAutoCall(taskId);
        } catch (e) {
            console.log(e);
        }

    };

    private _firstStartProgressiveAutoCallAfterLogin = () => {
        if (this._store.currentOrder.isEmptyCurrentOrder && this._currentUser.isReady) {
            this.call('100');
        }
    };

    @action
    private _connect: IConnectFn = (sipLogin, sipPass, sipHost) => {

        const login = `sip:${sipLogin}@${sipHost}`;
        const server = `wss://${sipHost}:8089/ws`;

        if (!this._socket || this._server !== server) {
            this._socket = new WebSocketInterface(server);
        }

        this._server = server;

        const configuration = {
            sockets: [this._socket],
            uri: login,
            password: sipPass,
        };

        this._ua = new UA(configuration);

        // https://jssip.net/documentation/3.8.x/api/ua/#section_events
        this._ua.on('connecting', (/*event: UAConnectingEvent*/) => {
            console.log('ua connecting event');
            this.setStatus(EnumSipStatus.CONNECTING);
        });

        this._ua.on('connected', (/*event: ConnectedEvent*/) => {
            console.log('ua connected event');
            this.setStatus(EnumSipStatus.CONNECTED);
        });

        this._ua.on('sipEvent', () => {
            console.log('ua sipEvent event');
            // TODO: че-то нет событий тут
        });

        this._ua.on('disconnected', (/*event: DisconnectEvent*/) => {
            console.log('ua disconnected event');
            this.setStatus(EnumSipStatus.DISCONNECTED);
            this._currentOrder.setForceInactiveButtonOfCall(false);
        });

        this._ua.on('registered', (/*event: RegisteredEvent*/) => {
            if (this._store.currentUser.mode === UserModeEnum.PROGRESSIVE) {
                void this._lounchFuncForAutoCall();
            }
            console.log('ua registered event');
            this.setStatus(EnumSipStatus.REGISTERED);
        });

        this._ua.on('unregistered', (/*event: UnRegisteredEvent*/) => {
            console.log('ua unregistered event');

            if (this._store.currentUser.mode === UserModeEnum.CLIENT_SERVICE) {
                this.setStatus(EnumSipStatus.DISCONNECTING_IN_PROGRESS);
            } else {
                this.setStatus(EnumSipStatus.CONNECTED);
            }
        });

        this._ua.on('registrationFailed', (/*event: UnRegisteredEvent*/) => {
            console.log('ua registrationFailed event');
            this.setStatus(EnumSipStatus.REGISTRATION_FAIL, SipHttpErrorCode.SERVICE_UNAVAILABLE);
        });

        this._ua.on('newMessage', (event: IncomingMessageEvent) => {
            console.log('newMessage');
            if (!this.zorraTaskId) {
                void this._getOrderFromProgressiveAutoCall(event);
                this.setStatus(EnumSipStatus.NEW_MESSAGE, SipHttpErrorCode.OK);
            }
        });


        /**
         * Fired for an incoming or outgoing session/call.
         */
        this._ua.on('newRTCSession', (ev: IncomingRTCSessionEvent | OutgoingRTCSessionEvent) => {
            // тут слушаем события только для входящей линии
            console.log('ua newRTCSession event');

            this._session = ev.session;

            // Обрабатываем только входящие звонки в этом случае
            if (this._session.direction === 'incoming') {
                console.log(this._session.direction === 'incoming');
                console.log('stream incoming  -------->');

                this._session.on('connecting', (/*e: ConnectingEvent*/) => {
                    console.log('incoming session connecting event');
                    this.setStatus(EnumSipStatus.CONNECTING);
                });

                this._session.on('peerconnection', (/*e: PeerConnectionEvent*/) => {
                    console.log('incoming session peerconnection event');
                    this.setStatus(EnumSipStatus.CONNECTED);
                });

                this._session.on('ended', (e: EndEvent) => {
                    console.log('incoming session ended event');
                    void this.callProcessEnded(e);
                });

                this._session.on('failed', (e: EndEvent) => {
                    console.log('incoming session failed event');
                    void this.callProcessFailed(e);
                });

                this._session.on('accepted', (/*e: IncomingEvent | OutgoingEvent*/) => {
                    console.log('incoming session accepted event');
                });

                this._session.on('confirmed', (e: any) => {
                    console.log('incoming session confirmed event');
                    this.setStatus(EnumSipStatus.LIVE, SipHttpErrorCode.OK);
                    console.log('Incoming call from number', e.ack.from._uri._user);
                    this.setPhoneNumber(e.ack.from._uri._user);
                });

                const options: AnswerOptions = {
                    mediaConstraints: {
                        audio: true,
                        video: false,
                    },
                    pcConfig: {
                        rtcpMuxPolicy: 'require',
                        iceServers: [],
                    },
                };

                // Отвечает на входящий звонок
                this._session.answer(options);

                // Исключительно для входящей линии надо использлвать addEventListener('addstream'), т.к. событие
                // this._session.on('addstream') не происходит, так де как и ontrack
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this._session.connection.addEventListener('addstream', (e: MediaStreamEvent) => {
                    const audio = document.createElement('audio');
                    audio.srcObject = e.stream;
                    void audio.play();
                });
            }
        });

        /**
         * Connects to the WebSocket server and restores the previous state if previously stopped.\
         * For a fresh start, registers with the SIP domain if register parameter in the UA’s configuration is set to true.
         */
        this._ua.start();
    };

    @action
    public disconnect = (): void => {
        this.setMuted(false);
        if (this._ua) {
            try {
                // гасит все звонки
                this._ua.terminateSessions({});
                // отправляет в сокет, что хочет разрегестироваться
                this._ua.unregister({ all: true });
                /**
                 * Saves the current registration state and disconnects from the WebSocket server\
                 * after gracefully unregistering and terminating active sessions if any.
                 */
                this._ua.stop();
                this._session = undefined;
                this._ua = undefined;
            } catch {
                this.setStatus(EnumSipStatus.INTERNAL_ERROR, SipHttpErrorCode.JS_SIP_INTERNAL_ERROR);
            }
        }
    };

    @action
    private async _endCallAutoCallAfterReload() {
        const taskId = this._localStorageService.getItem('taskIdProgressiveAutocall');

        if (taskId) {
            await this._callHistoryApiService.hangUpAutoCallByTask(taskId);
            this._currentUser.setIsReady(false);
            this._localStorageService.removeItem('taskIdProgressiveAutocall');
        }
    }

    @action
    private async _lounchFuncForAutoCall(): Promise<void> {
        await this._orderService.releaseOrderAutoCallAfterReload();
        await this._endCallAutoCallAfterReload();

        this._firstStartProgressiveAutoCallAfterLogin();
    }

    @action
    public endCall = (): void => {
        this.setMuted(false);
        this.setPhoneNumber('');
        if (this._ua) {
            this._ua.terminateSessions({});
            this._session = undefined;
        }
    };
}


export default SipServiceBase;

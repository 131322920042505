import { applySnapshot } from 'mobx-state-tree';

import RecallsApiService from '@api/recalls-api-service';
import { IRecallModelSnapshotIn } from '@models/mobx-state-tree/recalls.model';
import { IRecallProductsModelSnapshotIn } from '@models/mobx-state-tree/recallsProducts.model';
import { IRecall, IRecallOrderProducts } from '@interfaces/recalls.interface';
import { Store } from '@store/store';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import { action, computed, observable } from 'mobx';
import ModalService from '../core/services/ModalService';
import { ConfirmModalAction, ConfirmModalType } from '@core/models/ModalWindow';
import I18NService from './I18NService';
import { v4 } from 'uuid';
import { IOrder, IOrderProduct } from '@api/order-api-service/models';
import { getDecription } from '@core/helpers/getDecription';


export enum FetchStatusEnum {
    NONE = 1,
    PENDING = 2,
    SUCCESS = 3,
    FAIL = 4,
}

class RecallsService {
    constructor(
        private readonly _recallsApiService: RecallsApiService,
        private readonly _store: Store,
        private readonly _modalService: ModalService,
        private readonly _I18NService: I18NService,
    ) {

    }

    @observable
    public fetchStatus: FetchStatusEnum = FetchStatusEnum.NONE;

    @action
    private _setFetchStatus = (fetchStatus: FetchStatusEnum): void => {
        this.fetchStatus = fetchStatus;
    };

    @computed
    public get recallsList() {
        return this._store.recallsList;
    }

    public prepareRecalls(data: IRecall[] | IOrder[]): Record<string, IRecallModelSnapshotIn> {
        return convertArrayToCollection<IRecallModelSnapshotIn>(
            (data || []).map((recall: IRecall | IOrder): IRecallModelSnapshotIn => ({
                customerFullName: recall.customer_full_name,
                id: recall.id,
                recallDate: recall.recall_date,
                phone: getDecription(recall.secure.customer_phone),
                comment: recall.order_comment,
                createdDate: recall.created_at,
                orderProducts: convertArrayToCollection<IRecallProductsModelSnapshotIn>(
                    recall.orderProducts.map((orderProduct: IRecallOrderProducts | IOrderProduct): IRecallProductsModelSnapshotIn => <IRecallProductsModelSnapshotIn>({
                        id: v4(),
                        gift: orderProduct.gift,
                        promo: orderProduct.promo,
                        name: orderProduct.product.name,
                    })),
                ),
                foreignId: recall.foreign_id,
                partnerName: recall.partner.name,
                countryName: recall.country.name,
                countryFlag: recall.country.language ? recall.country.language.icon : '',
                maskPhones: recall?.form?.mask_phones || false,
            })),
        );
    }

    public fetchRecallsList = async (): Promise<void | never> => {
        try {
            this._setFetchStatus(FetchStatusEnum.PENDING);
            const result = await this._recallsApiService.getRecalls();
            const recallsSnapshotIn = this.prepareRecalls(result);
            if (recallsSnapshotIn) {
                applySnapshot(this._store.recalls, recallsSnapshotIn);
            }
            this._setFetchStatus(FetchStatusEnum.SUCCESS);
        } catch (error) {
            this._setFetchStatus(FetchStatusEnum.FAIL);
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    public editOrder = async (orderId: number, userId: number) => {
        try {
            const result = await this._recallsApiService.appointmentOperator(orderId, userId);
            return result;
        } catch (error) {
            const text = this._I18NService.t(
                `Заказ №${orderId} в работе у другого оператора.`,
                `Order №${orderId} in work with another operator.`,
            );
            await this._modalService.showConfirmModal(
                text,
                ConfirmModalType.Yes,
                10000,
                ConfirmModalAction.Yes,
            );
            console.error(error);
            return false;
        }
    };
}


export default RecallsService;

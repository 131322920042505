import { toFactory } from 'react-ioc';

import UserService from '@services/UserService';
import { Store } from '@store/store';
import UserApiService from '@api/user-api-service';
import FileStorageService from '@api/file-storage-api-service';
import CookieService from '@core/services/CookiesService';
import I18NService from '@services/I18NService';
import { CustomerModeStore } from '@store/customerModeStore';
import InternetSpeedMetricService from '@core/services/InternetSpeedMetricService';
import ModalService from '@core/services/ModalService';
import LocalStorageService from '@core/services/LocalStorageService';
import UserGreetingService from '@services/UserGreetingService';
import LogRocketService from '@services/Logging/LogRocket';
import SentryService from '@services/Logging/Sentry';


const UserServiceDI = (): any => [
    UserService,
    toFactory(
        [
            Store,
            UserApiService,
            FileStorageService,
            CookieService,
            I18NService,
            CustomerModeStore,
            InternetSpeedMetricService,
            ModalService,
            UserGreetingService,
            LocalStorageService,
            LogRocketService,
            SentryService,
        ],
        (
            store: Store,
            userApiService: UserApiService,
            fileStorageService: FileStorageService,
            cookieService: CookieService,
            I18NService: I18NService,
            customerModeStore: CustomerModeStore,
            internetSpeedMetricService: InternetSpeedMetricService,
            modalService: ModalService,
            userGreetingService: UserGreetingService,
            localStorageService: LocalStorageService,
            logrocketService: LogRocketService,
            sentryService: SentryService,
        ) => new UserService(
            store,
            userApiService,
            fileStorageService,
            cookieService,
            I18NService,
            customerModeStore,
            internetSpeedMetricService,
            modalService,
            userGreetingService,
            localStorageService,
            logrocketService,
            sentryService,
        ),
    ),
];


export default UserServiceDI;

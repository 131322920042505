import { provider } from 'react-ioc';

import ValidateService from '@core/services/ValidateService';
import CookieService from '@core/services/CookiesService';
import TranslationApiService from '@api/translation-api-service';
import AuthApiService from '@api/auth-api-service';
import CustomerApiService from '@api/customer-api-service';
import UserApiService from '@api/user-api-service';
import CallApiService from '@api/call-api-service';
import KladrApiService from '@api/kladr-api-service';
import OrderApiService from '@api/order-api-service';
import ScriptApiService from '@api/script-api-service';
import PartnerApiService from '@api/partner-api-service';
import SupportApiService from '@api/support-api-service';
import SourceApiService from '@api/source-api-service';
import CountryApiService from '@api/country-api-service';
import CallHistoryApiService from '@api/call-history-api-service';
import FileStorageService from '@api/file-storage-api-service';
import CommunicationQualityApiService from '@api/communication-quality-api-service';
import NotificationApiService from '@api/notification-api-service';
import OperatorStatisticApiService from '@api/operator-statistic-api-service';
import VerifierApiService from '@api/verifier-api-service';
import HistoryService from '@services/HistoryService';
import SipServiceBaseDI from '@app/servicesDI/SipServiceBaseDI';
import SidebarServiceDI from '@app/servicesDI/SidebarServiceDI';
import StoreDI from '@app/servicesDI/StoreDI';
import NotificationPollingServiceDI from '@app/servicesDI/NotificationPollingServiceDI';
import SipServiceDI from '@app/servicesDI/SipServiceDI';
import WebSocketServiceDI from '@app/servicesDI/WebSocketServiceDI';
import AuthServiceDI from '@app/servicesDI/AuthServiceDI';
import UserServiceDI from '@app/servicesDI/UserServiceDI';
import OrderUpdateServiceDI from '@app/servicesDI/OrderUpdateServiceDI';
import OrderFetchServiceDI from '@app/servicesDI/OrderFetchServiceDI';
import OrderServiceDI from '@app/servicesDI/OrderServiceDI';
import DeliveriesServiceDI from '@app/servicesDI/DeliveriesServiceDI';
import ModalServiceDI from '@app/servicesDI/ModalServiceDI';
import CheckCookieEnabledServiceDI from '@app/servicesDI/CheckCookieEnabledServiceDI';
import OrderCreationServiceDI from '@app/servicesDI/OrderCreationServiceDI';
import CustomerServiceDI from '@app/servicesDI/CustomerServiceDI';
import ClientServiceModeEventSubscriptionServiceDI
    from '@app/servicesDI/subscription/ClientServiceModeEventSubscriptionServiceDI';
import CommonStorageEventSubscriptionServiceDI
    from '@app/servicesDI/subscription/CommonStorageEventSubscriptionServiceDI';
import OrderUpdateEventSubscriptionServiceDI from '@app/servicesDI/subscription/OrderUpdateEventSubscriptionServiceDI';
import SipServiceBaseEventSubscriptionServiceDI
    from '@app/servicesDI/subscription/SipServiceBaseEventSubscriptionServiceDI';
import NotificationSubscriptionServiceDI from '@app/servicesDI/subscription/NotificationSubscriptionServiceDI';
import OrderCreationEventSubscriptionServiceDI
    from '@app/servicesDI/subscription/OrderCreationEventSubscriptionServiceDI';
import CustomerModeStoreDI from '@app/servicesDI/CustomerModeStoreDI';
import AudioServiceDI from '@app/servicesDI/AudioServiceDI';
import DevicesServiceDI from '@app/servicesDI/DevicesServiceDI';
import OldPromotionApiService from '@api/old-promotion-api-service';
import ErrorService from '@core/services/ErrorService';
import KibanaServiceDI from '@app/servicesDI/KibanaServiceDI';
import InternetSpeedMetricServiceDI from '@app/servicesDI/InternetSpeedMetricServiceDI';
import CookieSubscriptionServiceDI from '@app/servicesDI/subscription/CookieSubscriptionServiceDI';
import InternetSpeedMetricSubscriptionServiceDI
    from '@app/servicesDI/subscription/InternetSpeedMetricSubscriptionServiceDI';
import PddLimitHandlerDI from '@app/servicesDI/automatization/PddLimitHandlerDI';
import NoAnswerAfterSecHandlerDI from '@app/servicesDI/automatization/NoAnswerAfterSecHandlerDI';
import SipErrorCauseHandlerDI from '@app/servicesDI/automatization/SipErrorCauseHandlerDI';
import CallAutomatizationHandlerDI from '@app/servicesDI/automatization/CallAutomatizationHandlerDI';
import LocalStorageService from '@core/services/LocalStorageService';
import UserGreetingServiceDI from '@app/servicesDI/UserGreetingServiceDI';
import InactivityOperatorStorageServiceDI from '@app/servicesDI/InactivityOperatorStorageServiceDI';
import OldPromotionServiceDI from '@app/servicesDI/OldPromotionServiceDI';
import OffersServiceDI from '@app/servicesDI/OffersServiceDI';
import ProductsServiceDI from '@app/servicesDI/ProductsServiceDI';
import BasketServiceDI from '@app/servicesDI/BasketServiceDI';
import PromotionBuilderServiceDI from '@app/servicesDI/PromotionBuilderServiceDI';
import PromotionBuilderApiService from '@api/promotion-builder-api-service';
import LogRocketService from '@services/Logging/LogRocket';
import SentryService from '@services/Logging/Sentry';
import I18NServiceDI from '@app/servicesDI/I18NServiceDI';
import SnackbarServiceDI from '@app/servicesDI/SnackbarServiceDI';
import GeoNegiosMapApiService from '@api/geo-negios-map-api-service';
import GeoNegociosMapServiceDI from '@app/servicesDI/GeoNegociosMapServiceDI';
import ApiBase from '@core/api';

import App from './App';
import RecallsApiService from './core/api/recalls-api-service';
import RecallsServiceDI from './servicesDI/RecallsServiceDI';
import VerifierServiceDI from './servicesDI/VerifierServiceDI';
import ScriptDataServiceDI from '@app/servicesDI/ScriptDataServiceDi';
import PostProcessingServiceDI from '@app/servicesDI/PostProcessingServiceDI';
import KladrServiceDI from '@app/servicesDI/KladrServiceDI';
import CallHandlerDI from '@app/servicesDI/call-handler/CallHandlerDI';



// TODO Провести рефакторинг сервисов. Те сервисы, которые задействованы исключительно в рамках одного роута, должны там-же регистрироваться.
export default provider(
    ApiBase,
    SentryService,
    LogRocketService,
    ErrorService,
    ValidateService,
    LocalStorageService,
    CookieService,
    FileStorageService,
    CommunicationQualityApiService,
    NotificationApiService,
    HistoryService,
    TranslationApiService,
    AuthApiService,
    CustomerApiService,
    UserApiService,
    CallApiService,
    KladrApiService,
    OrderApiService,
    PartnerApiService,
    SupportApiService,
    PromotionBuilderApiService,
    SourceApiService,
    CountryApiService,
    CallHistoryApiService,
    OldPromotionApiService,
    GeoNegiosMapApiService,
    OperatorStatisticApiService,
    RecallsApiService,
    VerifierApiService,

    ScriptApiService,

    GeoNegociosMapServiceDI(),
    I18NServiceDI(),
    BasketServiceDI(),
    ProductsServiceDI(),
    OffersServiceDI(),
    OldPromotionServiceDI(),
    PromotionBuilderServiceDI(),
    InactivityOperatorStorageServiceDI(),
    UserGreetingServiceDI(),
    InternetSpeedMetricServiceDI(),
    InternetSpeedMetricSubscriptionServiceDI(),
    KibanaServiceDI(),
    CookieSubscriptionServiceDI(),
    DevicesServiceDI(),
    SipServiceBaseDI(),
    SidebarServiceDI(),
    StoreDI(),
    KladrServiceDI(),
    NotificationPollingServiceDI(),
    SipServiceDI(),
    WebSocketServiceDI(),
    AuthServiceDI(),
    UserServiceDI(),
    PostProcessingServiceDI(),
    OrderUpdateServiceDI(),
    OrderFetchServiceDI(),
    OrderServiceDI(),
    DeliveriesServiceDI(),
    ModalServiceDI(),
    SnackbarServiceDI(),
    CheckCookieEnabledServiceDI(),
    OrderCreationServiceDI(),
    CustomerServiceDI(),
    AudioServiceDI(),
    CustomerModeStoreDI(),
    CallAutomatizationHandlerDI(),
    CallHandlerDI(),
    PddLimitHandlerDI(),
    NoAnswerAfterSecHandlerDI(),
    SipErrorCauseHandlerDI(),
    RecallsServiceDI(),
    VerifierServiceDI(),
    ScriptDataServiceDI(),

    ClientServiceModeEventSubscriptionServiceDI(),
    CommonStorageEventSubscriptionServiceDI(),
    OrderUpdateEventSubscriptionServiceDI(),
    SipServiceBaseEventSubscriptionServiceDI(),
    NotificationSubscriptionServiceDI(),
    OrderCreationEventSubscriptionServiceDI(),
)(App);

import { action, computed, observable, reaction } from 'mobx';
import i18next, { TFunction } from 'i18next';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import pupa from 'pupa';

import { ITranslationList } from '@api/translation-api-service/model';
import TranslationApiService from '@api/translation-api-service';
import LocalStorageService from '@core/services/LocalStorageService';
import createI18nextInstance from '@/plugins/createI18nextInstance';
import { IUserLanguageData } from '@api/auth-api-service/models';

const STORAGE_KEY = 'languages_for_translation';
const ITEM_MAX_LIFETIME = 1000 * 60 * 60 * 24; // 1 сутки

type _ITranslationList = ITranslationList;
export type customI18NTFunction = TFunction;

class I18NService {
    private static _russianTranslateFunction = (key: string, defaultValue: string, options?: any): string => {
        if (options) {
            return pupa(key, options);
        }

        return key;
    };

    private static _defaultValueTranslateFunction = (key: string, defaultValue: string, options?: any): string => {
        if (options) {
            return pupa(defaultValue, options);
        }

        return defaultValue;
    };

    @observable
    public dateFnsLocale: any;

    @observable
    public _t: customI18NTFunction = I18NService._defaultValueTranslateFunction as customI18NTFunction;

    @observable
    private languageList: _ITranslationList[];

    @observable
    private _selectedLanguage: _ITranslationList;

    constructor(
        private readonly translationApiService: TranslationApiService,
        private readonly _localStorageService: LocalStorageService,
    ) {
        this._maybeRemoveOldRecords();

        reaction<string>(
            () => this._selectedLanguage?.locale,
            this._fnsTranslationAdditionProcessing.bind(this),
        );
    }

    @computed
    public get selectedLanguage(): _ITranslationList {
        return this._selectedLanguage;
    }

    @computed
    public get t(): customI18NTFunction {
        if (this._selectedLanguage?.locale === 'ru-RU' || i18next.language === 'ru-RU') {
            return I18NService._russianTranslateFunction as customI18NTFunction;
        }

        return this._t;
    }

    @computed
    public get getLanguageList(): _ITranslationList[] {
        return this.languageList;
    }

    @computed
    public get getParsedLanguageList(): _ITranslationList[] {
        return map(this.languageList, (value) => ({
            id: value.id,
            name: value.name,
            locale: value.locale,
            icon: value.icon,
        }));
    }

    private async _fnsTranslationAdditionProcessing(locale: string): Promise<void> {
        let _locale = '';

        if (locale === 'ru-RU') {
            _locale = 'ru';
        } else if (locale === 'id-ID') {
            _locale = 'id';
        } else if (locale === 'bn_BD') {
            _locale = 'bn';
        } else if (locale === 'th_TH') {
            _locale = 'th';
        } else if (locale === 'ms_MY') {
            _locale = 'ms';
        } else if (locale === 'es-ES') {
            _locale = 'es';
        } else if (locale === 'km_KH') {
            _locale = 'km';
        } else if (locale === 'pt-PT') {
            _locale = 'pt';
        } else {
            _locale = locale;
        }

        try {

            /**
             *  для сингальского языка (Шри-Ланка) и непальского языка в библиотеке date-fns отсуствуют нужные локализации,
             *  поэтому подгружаем локализацию для английского языка
             */

            this.dateFnsLocale = _locale === 'si_LK' || _locale === 'ne_NP' ?
                await import('date-fns/locale/en-US/index') :
                await import(`date-fns/locale/${_locale}/index.js`);

        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);

            // В случае если перевода не нашли, то ставим по умолчанию английский
            this.dateFnsLocale = await import('date-fns/locale/en-US/index');
        }
    }

    // Удаляем закешированные ключи языков для перевода если время их жизни вышло
    private _maybeRemoveOldRecords(): void {
        const data = this._localStorageService.storageTranslation;
        const now = new Date().getTime();
        forEach(data, (item) => {
            if (item) {
                if (!item.timestamp || item.timestamp + ITEM_MAX_LIFETIME <= now) {
                    item = null;
                }
            }
        });
    }

    @action
    public setSelectedLanguage = (languageItem: _ITranslationList): void => {
        this._selectedLanguage = languageItem;
    };

    @action
    public createInstance = async (): Promise<void | never> => {
        try {
            this._t = await createI18nextInstance();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error while creating t function instance');
            throw new Error(error);
        }
    };

    @action
    public register = async (): Promise<void> => {
        await this.createInstance();
    };

    // Сменить язык в i18 и отправить данные на бэк
    @action
    public changeLanguage = async (languageItem: _ITranslationList, userLanguage?: IUserLanguageData | null): Promise<void> => {
        try {
            await i18next.changeLanguage(languageItem.locale);
            await this.createInstance();

            if (!userLanguage) {
                await this.translationApiService.setUserLocale(languageItem.id);
            }
            // если id языка на смену не равен тому, который был ранее выбран
            // и если this.store.currentUser.language.id не равен id языка, на который меняем, то отправляем изменения на бэк
            if (userLanguage && userLanguage.id !== languageItem.id) {
                await this.translationApiService.setUserLocale(languageItem.id);
            }

            this.setSelectedLanguage(languageItem);
        } catch (error) {
            throw new Error(error);
        }
    };

    @action
    // Получить список доступных языков для смены в интерфейсе
    public fetchLanguageList = async (): Promise<void | never> => {
        if (typeof (localStorage) !== 'undefined') {
            const now = new Date().getTime();

            // пробуем найти в localStorage наш ключик
            // если находится и данные не просрочились, то возвращаем без запроса на бэк
            // иначе фетчим языки с бэка, складываем в localStorage с новым timeStamp-ом:
            const dataInStorage = this._localStorageService.storageTranslation;

            const dataInStorage2 = dataInStorage[STORAGE_KEY];
            if (dataInStorage2) {
                if (dataInStorage2.timestamp + ITEM_MAX_LIFETIME <= now) {
                    try {
                        this.languageList = await this.translationApiService.getLanguages();
                        const dataToSave = this._localStorageService.storageTranslation;
                        dataToSave[STORAGE_KEY] = {
                            languageList: this.languageList,
                            timestamp: new Date().getTime(),
                        };
                        this._localStorageService.storageTranslation = dataToSave;
                    } catch (error) {
                        throw new Error(error);
                    }
                } else { // возвращаем данные из localStorage:
                    this.languageList = dataInStorage2.languageList;
                }
            } else {
                try {
                    this.languageList = await this.translationApiService.getLanguages();
                    const dataToSave = this._localStorageService.storageTranslation;
                    dataToSave[STORAGE_KEY] = {
                        languageList: this.languageList,
                        timestamp: new Date().getTime(),
                    };
                    this._localStorageService.storageTranslation = dataToSave;
                } catch (error) {
                    throw new Error(error);
                }
            }
        } else { // если не поддерживается localStorage
            try {
                this.languageList = await this.translationApiService.getLanguages();
            } catch (error) {
                throw new Error(error);
            }
        }
    };
}


export default I18NService;

import React, {
    FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import {
    CurrentProductsTabs,
    IDataTab,
} from '@components/main/order-page/order-products/models';
import I18NService from '@services/I18NService';
import { useInstances } from 'react-ioc';
import { Store } from '@store/store';
import OrderService from '@services/order/OrderService';
import PlusIconSvg from '@images/sidebar/PlusIconSvg.svg';
import { IProductItemModel, ProductItemTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import BlockWithBonusProductsPromotionBuilder
    from '@components/main/order-page/order-products/block-with-bonus-products/BlockWithBonusProductsPromotionBuilder';
import BlockWithBonusProducts
    from '@components/main/order-page/order-products/block-with-bonus-products/BlockWithBonusProducts';
import ProductsService from '@services/order/ProductsService';
import OrderTable from '../order-table/OrderTable';
import SimilarOrderTable from '../order-table/SimilarOrderTable';
import ProductTable from '../products-table/ProductTable';
import { useTopFloatingNotificationPortalHeight } from './includes/useTopFloatingNotificationPortalHeight';
import OrderTablePromotionBuilder from '../order-table/PromotionBuilder/Order/OrderTablePromotionBuilder';
import { OrderFetchService } from '@app/services';


const ACTIVE_TAB = 'active';

const OrderTableDesktop: FunctionComponent = (): JSX.Element => {
    const [tab, setTab] = useState<Record<string, 'active'>>((): Record<string, 'active'> => ({
        [CurrentProductsTabs.CURRENT_ORDER]: ACTIVE_TAB,
    }));

    const [
        {
            currentOrder: {
                mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved,
                haveItemsInBasket,
                similarOrdersValues,
                mainItemsInBasket,
                promoItemsInBasket,
                giftsNotRelatedToOldPromotions,
                dynamicSetItemsInBasket,
                staticSetItemsInBasket,
                animationOnOrErrorFieldsActive,
                mainRegularItemsInCatalog,
                dynamicSetItemsInCatalog,
                staticSetItemsInCatalog,
                itemsToShowInCatalogTab,
                haveItemsInCatalog,
                animationOnAddToBasket,
                itemsCountInBasket,
                additionalParamsCurrentOrder: {
                    activePromotionBuilderModelId,
                },
                form: {
                    maskPhones,
                },
                id,
                similarCount,
            },
            currentUser: {
                canEditProductPrice,
            },
        },
        orderService,
        { t },
        {
            hasPromoItemsInCart,
            isConditionForPromoCarts,
        },
        orderFetchService,
    ] = useInstances(
        Store,
        OrderService,
        I18NService,
        ProductsService,
        OrderFetchService,
    );

    const tabTopProperty = useTopFloatingNotificationPortalHeight();

    const allCatalogItemsWithoutConstituentElementsOfSet = useMemo<IProductItemModel[]>(
        (): IProductItemModel[] => itemsToShowInCatalogTab.filter((x) => x.type !== ProductItemTypeEnum.SET_COMPOSITION_ITEM),
        [itemsToShowInCatalogTab],
    );

    const dataTabs: IDataTab[] = [
        { text: t('Заказ', 'Order'), state: CurrentProductsTabs.CURRENT_ORDER },
        { text: t('Каталог', 'Catalog'), state: CurrentProductsTabs.ADD_FROM_CATALOG, svg: PlusIconSvg },
        { text: t('Похожие заказы', 'Similar orders'), state: CurrentProductsTabs.SIMILAR_ORDER },
    ];

    const TabTemplate: FunctionComponent<IDataTab> = ({ text, state, svg }) => {

        const changeTable = useCallback(() => {
            setTab({ [state]: ACTIVE_TAB });

            if (state === CurrentProductsTabs.SIMILAR_ORDER) {
                void orderFetchService.fetchSimilarOrders(id, maskPhones);
            }

            const currentOrderTab = document.getElementById('order-items');
            if (currentOrderTab) {
                currentOrderTab.scrollIntoView({ block: 'start' });

                // Получаем высоту контента в уведомлениях чтобы заскролиться чуть нихе их
                const el = document.getElementById('top-floating-notification-portal');
                if (el) {
                    const h = el.offsetHeight;

                    if (h) {
                        // Контейнер custom-scroll только так достучаться до него.
                        const container = document.querySelector('.order-page-form-scroll-container > div');
                        if (container) {
                            container.scrollTop -= h + 5;
                        }
                    }
                }
            }
        }, [tab]);

        return (
            <div
                className={cn(
                    'currentProducts__tab',
                    tab[state],
                    state === CurrentProductsTabs.CURRENT_ORDER && animationOnAddToBasket && 'animate-me shake-up colorize-me',
                )}
                onClick={changeTable}
                id={state}
            >
                {
                    svg && <img src={svg} alt={text} className="menu-item-icon" />
                }
                <div className="currentProducts__text">
                    {text}
                    {state === CurrentProductsTabs.SIMILAR_ORDER && (
                        <span className={`counter ${!similarCount && !tab[state] && 'counter__empty'}`}>{similarCount}</span>
                    )}
                    {state === CurrentProductsTabs.CURRENT_ORDER && (
                        <span className="counter">{itemsCountInBasket}</span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="currentProducts__form">
                <div
                    className="currentProducts__form-tabs-container"
                    style={tabTopProperty}
                >
                    {dataTabs.map((x: IDataTab) => (
                        <TabTemplate
                            key={x.state}
                            text={x.text}
                            state={x.state}
                            svg={x.svg}
                        />
                    ))}
                </div>
                {activePromotionBuilderModelId && (
                    <OrderTablePromotionBuilder
                        isShow={tab[CurrentProductsTabs.CURRENT_ORDER] === ACTIVE_TAB}
                    />
                )}
                {!activePromotionBuilderModelId && (
                    <OrderTable
                        isShow={tab[CurrentProductsTabs.CURRENT_ORDER] === ACTIVE_TAB}
                        canEditProductPrice={canEditProductPrice}
                        haveItemsInBasket={haveItemsInBasket}
                        mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved={mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved}
                        mainItemsToShow={mainItemsInBasket}
                        mainItems={mainItemsInBasket}
                        giftItemsToShow={giftsNotRelatedToOldPromotions}
                        giftItems={giftsNotRelatedToOldPromotions}
                        promoItemsToShow={promoItemsInBasket}
                        promoItems={promoItemsInBasket}
                        dynamicItemsToShow={dynamicSetItemsInBasket}
                        dynamicItems={dynamicSetItemsInBasket}
                        staticItemsToShow={staticSetItemsInBasket}
                        staticItems={staticSetItemsInBasket}
                        isConditionForPromoCarts={isConditionForPromoCarts}
                        hasPromoItemsInCart={hasPromoItemsInCart}
                        setOrderProductPrice={orderService.setOrderProductPrice}
                        animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                        onAddToCatalogClick={setTab}
                    />
                )}
                <SimilarOrderTable
                    isShow={tab[CurrentProductsTabs.SIMILAR_ORDER] === ACTIVE_TAB}
                    similarOrders={similarOrdersValues}
                    animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                    mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved={mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved}
                    isPromotionBuilderActive={Boolean(activePromotionBuilderModelId)}
                />
                <ProductTable
                    isShow={tab[CurrentProductsTabs.ADD_FROM_CATALOG] === ACTIVE_TAB}
                    haveItemsInBasket={haveItemsInCatalog}
                    mainRegularItems={mainRegularItemsInCatalog}
                    dynamicItems={dynamicSetItemsInCatalog}
                    staticItems={staticSetItemsInCatalog}
                    animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                    allProducts={allCatalogItemsWithoutConstituentElementsOfSet}
                    mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved={mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved}
                    isPromotionBuilderActive={Boolean(activePromotionBuilderModelId)}
                />
            </div>
            {activePromotionBuilderModelId && <BlockWithBonusProductsPromotionBuilder />}
            {!activePromotionBuilderModelId && <BlockWithBonusProducts />}
        </>
    );
};


export default observer(OrderTableDesktop);
